.logo {
    width: 100%;
    margin-block: 2rem;
}

.version {
    font-weight: 900;
}

.item {
    margin-block: 2rem;
}

.btn {
    background-color: #424ff2;
    color: white;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 1em;
    padding: 0.6em 1.2em;
    border: 1px solid transparent;
}

.btn:hover {
    background-color: #6f7bf9;
}

.logger {
    list-style: decimal-leading-zero inside;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
}

.log {
    font: 1em source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    margin-block: 0.125em;
    line-height: 1.3333;
}

.log::marker {
    font-weight: 900;
    color: #424ff2;
}

code {
    background-color: #eee;
    padding: 0.125em 0.25em;
    border-radius: 0.25em;
}

pre {
    display: inline;
}
